import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import SupportForm from "../components/support-form";
import ContactInfo from "../components/contact-info";

const ContactPage = () => {
  const [userApp, setuserApp] = useState(null);
  const { strapiAbout, strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userApp = params.get("user");
    setuserApp(userApp);
    // console.log(userApp);
  }, []);

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Kontakta oss" }} />

      <main>
        <section id="contact" class="ud-pt-[120px] ud-pb-20">
          <div class="ud-container">
            <div class="ud-mx-[-16px] ud-flex ud-flex-wrap">
              <SupportForm user={userApp} />
              <ContactInfo />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ContactPage;
