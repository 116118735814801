import React, { useState } from "react";

const SupportFormModal = ({ modalOpen, setModalOpen }) => {
  return (
    <>
      {modalOpen ? (
        <div class="ud-relative ud-mt-10 ud-rounded-md ud-border ud-bg-white ud-p-5 ud-shadow-lg">
          <div class="ud-mt-3 ud-text-center">
            <div class="ud-mx-auto ud-mb-8 ud-flex ud-h-12 ud-w-12 ud-items-center ud-justify-center ud-rounded-full ud-bg-green-100">
              <svg
                class="ud-h-6 ud-w-6 ud-text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>
            <h3 class="ud-text-lg ud-font-medium ud-leading-6 ud-text-gray-900">
              Tack!
            </h3>
            <div class="ud-px-7 ud-py-1">
              <p class="ud-text-sm ud-text-gray-500">
                Vi återkommer så fort som möjligt med svar via email!
              </p>
            </div>
            <div class="ud-items-center ud-px-4 ud-py-3">
              <button
                id="ok-btn"
                onClick={() => setModalOpen(false)}
                class="ud-hover:bg-green-600 ud-focus:outline-none ud-focus:ring-2 ud-focus:ring-green-300 ud-w-full ud-rounded-full ud-bg-green-500 ud-px-4 ud-py-2 ud-text-base ud-font-medium ud-text-white ud-shadow-sm"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SupportFormModal;
