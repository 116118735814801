import React, { useState, useEffect } from "react";
import http from "../services/http";

import SupportFormModal from "./support-form-modal";

export const SupportForm = ({ user }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const createNewSupportTicket = async (data) => {
    if (name === "") return;
    if (email === "") return;

    if (message === "") return;
    setLoading(true);

    try {
      const response = await http.post(
        "/api/support-tickets",
        JSON.stringify({
          data: {
            name: name,
            email: email,
            text: message,
            userUUID: user,
          },
        })
      );
      // console.log(response);
      setModalOpen(true);
      setLoading(false);
      setMessage("");
      setEmail("");
      setName("");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className=""></div>

      <div class="ud-w-full ud-px-4 lg:ud-w-8/12">
        <div
          class="
                wow fadeInUp
                ud-mb-12
                ud-rounded-md ud-bg-primary ud-bg-opacity-[3%]
                ud-p-11
                dark:ud-bg-dark
                sm:ud-p-[55px]
                lg:ud-mb-5
                lg:ud-p-11
                xl:ud-p-[55px]
              "
          data-wow-delay=".15s
              "
        >
          <h2
            class="
                  ud-mb-3 ud-text-2xl
                  ud-font-bold
                  ud-text-black
                  dark:ud-text-white
                  sm:ud-text-3xl
                  lg:ud-text-2xl
                  xl:ud-text-3xl
                "
          >
            Har du en fråga eller behöver du hjälp?
          </h2>
          <p class="ud-mb-12 ud-text-base ud-font-medium ud-text-body-color">
            Vår support hör av sig ASAP via mail
          </p>

          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap">
            <div class="ud-w-full ud-px-4 md:ud-w-1/2">
              <div class="ud-mb-8">
                <label
                  for="name"
                  class="
                          ud-mb-3 ud-block ud-text-sm ud-font-medium
                          ud-text-dark
                          dark:ud-text-white
                        "
                >
                  Ditt namn
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Albert Larsson"
                  class="
                          ud-w-full ud-rounded-md ud-border
                          ud-border-transparent
                          ud-py-3 ud-px-6
                          ud-text-base
                          ud-text-body-color
                          ud-placeholder-body-color
                          ud-shadow-one
                          ud-outline-none
                          focus:ud-border-primary
                          focus-visible:ud-shadow-none
                          dark:ud-bg-[#242B51]
                          dark:ud-shadow-signUp
                        "
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div class="ud-w-full ud-px-4 md:ud-w-1/2">
              <div class="ud-mb-8">
                <label
                  for="email"
                  class="
                          ud-mb-3 ud-block ud-text-sm ud-font-medium
                          ud-text-dark
                          dark:ud-text-white
                        "
                >
                  Din e-post
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="albert.larsson@mail.se"
                  class="
                          ud-w-full ud-rounded-md ud-border
                          ud-border-transparent
                          ud-py-3 ud-px-6
                          ud-text-base
                          ud-text-body-color
                          ud-placeholder-body-color
                          ud-shadow-one
                          ud-outline-none
                          focus:ud-border-primary
                          focus-visible:ud-shadow-none
                          dark:ud-bg-[#242B51]
                          dark:ud-shadow-signUp
                        "
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div class="ud-w-full ud-px-4">
              <div class="ud-mb-8">
                <label
                  for="message"
                  class="
                          ud-mb-3 ud-block ud-text-sm ud-font-medium
                          ud-text-dark
                          dark:ud-text-white
                        "
                >
                  Ditt meddelande
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  placeholder="Beskriv här vad du undrar över eller det du behöver hjälp med"
                  class="
                          ud-w-full ud-resize-none ud-rounded-md
                          ud-border
                          ud-border-transparent ud-py-3
                          ud-px-6
                          ud-text-base
                          ud-text-body-color
                          ud-placeholder-body-color
                          ud-shadow-one
                          ud-outline-none
                          focus:ud-border-primary
                          focus-visible:ud-shadow-none
                          dark:ud-bg-[#242B51]
                          dark:ud-shadow-signUp
                        "
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div class="ud-w-full ud-px-4">
              <button
                class="ud-flex ud-items-center 
                        ud-rounded-full
                        ud-bg-primary
                        ud-py-4
                        ud-px-9
                        ud-text-base
                        ud-font-medium
                        ud-text-white ud-transition
                        ud-duration-300
                        ud-ease-in-out
                        hover:ud-bg-opacity-80
                        hover:ud-shadow-signUp
                      "
                onClick={createNewSupportTicket}
              >
                Skicka in ärendet
                {loading && (
                  <>
                    <svg
                      class="ud-ml-3 ud-h-5 ud-w-5 ud-animate-spin ud-text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="ud-opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="ud-opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </>
                )}
              </button>

              <SupportFormModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportForm;
