import React from "react";

export const ContactInfo = () => {
  return (
    <>
      <div class="ud-w-full ud-px-4 lg:ud-w-4/12">
        <div
          class="
                wow fadeInUp
                ud-mb-12
                ud-rounded-md ud-bg-primary ud-bg-opacity-[3%]
                ud-p-11
                dark:ud-bg-dark
                sm:ud-p-[55px]
                lg:ud-mb-5
                lg:ud-p-11
                xl:ud-p-[55px]
              "
          data-wow-delay=".15s
              "
        >
          <h2
            class="
                  ud-mb-3 ud-text-2xl
                  ud-font-bold
                  ud-text-black
                  dark:ud-text-white
                  sm:ud-text-3xl
                  lg:ud-text-2xl
                  xl:ud-text-3xl
                "
          >
            Info
          </h2>
          <p
            class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
          >
            <span
              class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
            >
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                class="ud-fill-current"
              >
                <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
              </svg>
            </span>
            Zwop Technology AB
          </p>
          <p
            class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
          >
            <span
              class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
            >
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                class="ud-fill-current"
              >
                <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
              </svg>
            </span>
            559334-0051
          </p>
          <p
            class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
          >
            <span
              class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
            >
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                class="ud-fill-current"
              >
                <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
              </svg>
            </span>
            hello@zwop.io
          </p>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
